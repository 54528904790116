import React from 'react';

import SEO from '../components/seo';
import T from '../components/T';
import Layout from '../layouts/default';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found - Hypersay" />
    <div style={{ display: 'flex', margin: '2em auto' }}>
      <div>
        <div
          style={{
            width: '100%',
            height: '0',
            paddingBottom: '75%',
            position: 'relative',
          }}
        >
          <iframe
            src="https://giphy.com/embed/l41YsxKKVYnucStag"
            width="100%"
            height="100%"
            style={{ position: 'absolute' }}
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        </div>
        <h1
          style={{
            color: 'var(--payne-grey)',
            fontSize: '3.2em',
            fontWeight: 'normal',
          }}
        >
          404 - <T id="NOT FOUND" />
        </h1>
        <p>
          <T id="You just hit a route that doesn't exist... the sadness." />
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
